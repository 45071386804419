import logo from "./png_file-06.png";
import "./App.css";
import { Button } from "@mui/material";

function App() {
  return (
    <div className="App">
      <div className="header-bar">
        <a href="http://app.ardorio.co">
          <Button className="button-style" variant="contained">
            Early Access Users
          </Button>
        </a>
      </div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <text>Coming Soon</text>
      </header>
    </div>
  );
}

export default App;
